import React from "react";
import { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { message, Table, Radio, Modal } from "antd";
import API from "../../../services/rest/api";
import ViewDietPlanTemplate from "../../../molecules/DiatPlanTemplate/ViewDietPlanTemplate";
import type { TablePaginationConfig } from "antd";
import AddDietContainer from "../../AddDietPlan/AddDietPlan";

import "./index.scss";

const DEFAULT_PAGE_SIZE = 10;

type DietTemplateCoachProps = {
  setIsButtonVisible?: any;
  setScreen?: any;
  selectedAssessment?: any;
  userDetails?: any;
  setEnableSwitch?: any;
  setToggle?: any;
};

const DietTemplateCoach: React.FC<DietTemplateCoachProps> = ({
  setIsButtonVisible,
  setScreen,
  selectedAssessment,
  userDetails,
  setEnableSwitch,
  setToggle,
}) => {
  const params = useParams();
  const location = useLocation();

  const [loading, setLoading] = useState<boolean>(false);
  const [templateList, setTemplateList] = useState<Array<any>>([]);
  const [addDietShowTemplate, setAddDietShowTemplate] = useState<any>(false);
  const [openDietModal, setOpenDietModal] = useState<boolean>(false);

  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);

  const [selectedRadioIndex, setSelectedRadioIndex] = useState<number | null>(null);
  const [selectedTemplateData, setSelectedTemplateData] = useState<any | null>(null);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: 0,
  });

  const columns = [
    {
      title: "Select",
      width: 100,
      render: (text: any, record: any, index: number) => (
        <Radio.Group
          onChange={(e) => handleRadioChange(e, index)}
          value={selectedRadioIndex}
          className="custom-radio"
        >
          <Radio value={index}></Radio>
        </Radio.Group>
      ),
    },
    {
      title: "Template Name",
      dataIndex: "template_name",
      key: "template_name",
      render: (name: string, record: any) => (
        <a
          style={{ color: "#760fb2", cursor: "pointer" }}
          onClick={() => handleViewTemplate(record.id, "view")}
        >
          {name}
        </a>
      ),
    },
    {
      title: "Total Calories Goal",
      dataIndex: "total_calories",
      key: "total_calories",
    },
    {
      title: "Actual Calories",
      dataIndex: "actual_calories",
      key: "actual_calories",
    },
    {
      title: 'Created By',
      dataIndex: 'coach_name',
      key: 'coach_name',
    },
  ];

  useEffect(() => {
    const token = params.token;
    localStorage.setItem(
      "token",
      location.state?.token === ":token" || location.state?.token === undefined
        ? token
        : location.state?.token
    );
    sessionStorage.setItem(
      "token",
      location.state?.token === ":token" || location.state?.token === undefined
        ? token
        : location.state?.token
    );
  }, [params]);

  const modifyPlanResponseData = (response: any[]) => {
    return response.map((resp: any, index: number) => ({
      ...resp,
      key: resp._id || index,
      id: resp._id || "",
    }));
  };


  const fetchTemplateData = async (page = 1, pageSize = DEFAULT_PAGE_SIZE) => {
    try {
      setLoading(true);
      const response = await API.GetDietTemplateList({ page, limit: pageSize });
      if (response?.code === 1) {
        const modifiedPlanList = modifyPlanResponseData(response?.data || []);
        setTemplateList(modifiedPlanList);
        setPagination({
          current: response?.current_page || 1,
          pageSize: response?.limit || DEFAULT_PAGE_SIZE,
          total: response?.total_records || 0,
        });
      }
    } catch (error) {
      console.log("Error fetching template list:", error);
      message.error({ content: "Failed to fetch template list!" });
    } finally {
      setLoading(false);
    }
  };

  const handleViewTemplate = async (templateId: any, type = "view") => {
    setSelectedTemplateId(templateId);
    setOpenDietModal(true);
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    fetchTemplateData(pagination.current, pagination.pageSize);
  };
  useEffect(() => {
    fetchTemplateData();
  }, []);

  const handleRadioChange = (e: any, index: number) => {
    setSelectedRadioIndex(index);
    setSelectedTemplateData(templateList[index]);
  };

  const onCloseViewModal = () => {
    setSelectedTemplateId(null);
    setOpenDietModal(false);
  };

  return (
    <div className="container-wrapper-coach">
      {!addDietShowTemplate && (
        <div>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <>
              <div
                className="table-wrapper coach-table"
                style={{ padding: "10px" }}
              >
                <Table
                  className="custom-table-diet"
                  dataSource={templateList}
                  columns={columns}
                  loading={loading}
                  pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                  }}
                  onChange={handleTableChange}
                />
              </div>

              <div className="button-wrapper select-button-wrapper">
                <button
                  onClick={async () => {
                    if (selectedRadioIndex !== null && selectedTemplateData) {
                      setAddDietShowTemplate(true);
                      setIsButtonVisible(false);
                    }
                  }}
                  className={`buttons select-button ${selectedRadioIndex === null ? "disabled" : ""
                    }`}
                  disabled={selectedRadioIndex === null}
                >
                  Select Template
                </button>
              </div>
            </>
          )}
        </div>
      )}
      {openDietModal && (
        <Modal
          className="view-assessment-score-modal"
          title="Diet Template"
          centered
          open={openDietModal}
          onOk={onCloseViewModal}
          onCancel={onCloseViewModal}
          width={"85vw"}
          footer={false}
        >
          <ViewDietPlanTemplate templateId={selectedTemplateId ?? null} />
        </Modal>
      )}

      {addDietShowTemplate && (
        <AddDietContainer
          templateId={selectedTemplateData?.id}
          editModal={true}
          setAddDietShow={setAddDietShowTemplate}
          setScreen={setScreen}
          selectedAssessment={selectedAssessment}
          userDetails={userDetails}
          setEnableSwitch={setEnableSwitch}
          setToggle={setToggle}
        />
      )}
    </div>
  );
};

export default DietTemplateCoach;
